import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import http from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'

export default function useTestimonialsList() {
  // Use toast
  const toast = useToast()

  const refTestimonialListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'author',
      label: 'Автор',
      sortable: true,
    },
    {
      key: 'profession',
      label: 'Профессия',
      sortable: true,
    },
    {
      key: 'star',
      label: 'Оценка',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Действие',
    },
  ]
  const perPage = ref(10)
  const totalTestimonials = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('title')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refTestimonialListTable.value ? refTestimonialListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTestimonials.value,
    }
  })

  const refetchData = () => {
    refTestimonialListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchTestimonials = (ctx, callback) => {
    http
      .post('https://edu.startupchoikhona.tj/backend/api/testimonial/index', {
        search: searchQuery.value,
        length: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const webinars = response.data.data.items
        callback(webinars)
        totalTestimonials.value = response.data.data.total_rows
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ошибка выборки список отзывов',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteTestimonial = (idTestimonial) => {
    Swal.fire({
      title: 'Вы действительно хотите удалить этот отзыв?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Удалить',
      customClass: {
        cancelButton: 'btn btn-outline-danger ml-1',
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
      .then(result => {
        if (result.value && idTestimonial > 0) {
          http
            .post(`https://edu.startupchoikhona.tj/backend/api/testimonial/delete/${idTestimonial}`, {})
            .then(response => {
              refetchData()
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Успешно удалено.',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Ошибка при удалении отзыва',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Удаление отзыв отменено',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  return {
    fetchTestimonials,
    deleteTestimonial,
    tableColumns,
    perPage,
    currentPage,
    totalTestimonials,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTestimonialListTable,
    refetchData,
  }
}
