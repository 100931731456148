<template>
  <b-sidebar
      id="add-new-testimonial-sidebar"
      :visible="isAddNewTestimonialSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="sideBarShown"
      @hidden="resetForm"
      :no-close-on-backdrop="true"
      @change="(val) => $emit('update:is-add-new-testimonial-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ testimonialId > 0 ? 'Редактировать отзыв' : 'Добавить новый отзыв' }}
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <!-- Автор -->
          <validation-provider
              #default="validationContext"
              name="Автор"
              rules="required"
          >
            <b-form-group
                label="Автор"
                label-for="author"
            >
              <b-form-input
                  id="author"
                  v-model="userData.author"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Профессия -->
          <validation-provider
              #default="validationContext"
              name="профессия"
              rules="required"
          >
            <b-form-group
                label="Профессия"
                label-for="profession"
            >
              <b-form-input
                  id="profession"
                  v-model="userData.profession"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                  autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Текст отзыв -->
          <validation-provider
              #default="validationContext"
              name="профессия"
              rules="required"
          >
            <b-form-group
                label="Текст отзыв"
                label-for="body"
            >
              <quill-editor
                  id="body"
                  v-model="userData.body"
                  :options="editorOption"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Оценка -->
          <validation-provider
              #default="validationContext"
              name="оценка"
              rules="required"
          >
            <b-form-group
                label="Оценка"
                label-for="star"
            >
              <b-form-rating
                  no-border
                  id="star"
                  v-model="userData.star"
                  show-value
                  inline
                  variant="warning"
                  :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-media class="mb-2">
            <template #aside>
              <b-avatar
                  ref="previewEl"
                  :src="userData.avatar"
                  :text="avatarText(userData.title)"
                  :variant="`light-primary`"
                  size="90px"
                  rounded
                  @click="$refs.refInputEl.click()"
              />
            </template>
            <div class="d-flex flex-wrap">
              <b-button
                  variant="primary"
                  @click="$refs.refInputEl.click()"
              >
                <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @input="inputImageRenderer"
                    accept="image/*"
                >
                <feather-icon
                    icon="DownloadIcon"
                    class="d-inline"
                />
              </b-button>
              <b-button
                  variant="outline-secondary"
                  class="ml-1"
              >
                <feather-icon
                    icon="TrashIcon"
                    class=""
                />
              </b-button>
            </div>
          </b-media>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              Сохранить
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              Отменить
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BMedia,
  BAvatar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BFormRating,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email, regex } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import http from '@axios'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
import * as Quill from 'quill'

Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    BSidebar,
    BMedia,
    BAvatar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormRating,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    ImageResize,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewTestimonialSidebarActive',
    event: 'update:is-add-new-testimonial-sidebar-active',
  },
  props: {
    isAddNewTestimonialSidebarActive: {
      type: Boolean,
      required: true,
    },
    testimonialId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      regex,
      editorOption: {
        theme: 'snow',
        bounds: '#scrolling-container',
        scrollingContainer: '#scrolling-container',
        placeholder: 'Описание',
        modules: {
          imageResize: {
          },
          toolbar: {
            container:  [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ],
          },
        },
      },

    }
  },
  methods: {
    sideBarShown() {
      this.getTestimonial()
    },
    getTestimonial() {
      if (this.testimonialId > 0) {
        this.$http
          .post(`https://edu.startupchoikhona.tj/backend/api/testimonial/${this.testimonialId}/view`, {
          })
          .then(response => {
            const {
              data,
            } = response.data
            this.userData = {
              author: data.author,
              profession: data.profession,
              body: data.body,
              star: data.star,
              avatar: null,
              image: null,
            }
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка при получение менторы',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.userData = {
          author: '',
          profession: '',
          body: '',
          star: 0,
          avatar: null,
          image: null,
        }
      }
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      author: '',
      profession: '',
      body: '',
      star: 0,
      avatar: null,
      image: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data)
          .forEach(key => {
            if (data[key] && key !== 'avatar') {
              buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
            }
          })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    }
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      userData.value.avatar = base64
      userData.value.image = refInputEl.value.files[0]
    })

    const onSubmit = () => {
      const formData = new FormData()

      buildFormData(formData, userData.value)
      if (props.testimonialId > 0) {
        http.post(`https://edu.startupchoikhona.tj/backend/api/testimonial/update/${props.testimonialId}`, formData)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-testimonial-sidebar-active', false)
          })
      } else {
        http.post('https://edu.startupchoikhona.tj/backend/api/testimonial/create', formData)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-testimonial-sidebar-active', false)
          })
      }

    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)
    return {
      avatarText,
      refInputEl,
      previewEl,
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      inputImageRenderer,
    }
  },
  created() {
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-testimonial-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#body .ql-editor {
  min-height: 300px;
}
</style>
